.main-found {
    background: #FAFAFA;
    min-height: 85vh;
}

.not-found__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.not-found__content > h3 {
    margin: 32px 0 48px;
}
.not-found__content > img {
    width: 433px;
    height: 287px;
}

@media screen and (min-width: 380px) and (max-width: 767px) {
    .not-found__content > img {
        width: 300px;
        height: 200px;
        object-fit: contain;
    }
}

@media screen and (min-width: 320px) and (max-width: 379px) {
    .main-found {
        min-height: 80vh;
    }
    .not-found__content > img {
        width: 250px;
        height: 200px;
        object-fit: contain;
    }
}